<template>
  <section>
    <h1 class="bold-header-text app-header mb-3">{{ title }}</h1>
    <b-card class="dashboard-card"></b-card>
  </section>
</template>
<script>
export default {
  props: ["companyID"],
  data() {
    return {
      title: "",
      additionalSite: {},
    };
  },
  created() {
    if (this.companyID === undefined || this.companyID === null) {
      this.title = "Add Branch Location";
      // init additionalSite to empty state
    } else {
      this.title = "Edit Branch Location";
      // make axios call to retrieve branch location from api using companyID prop
    }
  },
};
</script>
